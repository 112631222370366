import React from "react";
import "./SmartLinkService.scss";

export interface SmartLinkServiceProps {
  brandLogo: string;
  text: string;
  url: string;
  brandName?: string;
}

function customLogoStyle(brandName: string | undefined): React.CSSProperties | undefined {
  const defaultValues: React.CSSProperties = { position: "relative", height: "28px" };

  switch (brandName) {
    case "Apple Music":
      return { ...defaultValues, top: "-4px" };
    case "Bandcamp":
      return { ...defaultValues, top: "2px" };
    case "Spotify":
      return { ...defaultValues, height: "35px" };
    case "Soundcloud":
      return { ...defaultValues, height: "25px", left: "-2px" };
    case "Youtube":
      return { ...defaultValues, height: "25px" };
    case "Internet Archive":
      return { ...defaultValues, height: "32px" };
    default:
      return defaultValues;
  }
}

export const SmartLinkService = (props: SmartLinkServiceProps) => {
  return (
    <a className="smart-link-link" href={props.url} target="_blank" rel="noreferrer">
      <div className="smart-link-service">
        <img src={props.brandLogo} alt={props.brandName + " logo"} style={customLogoStyle(props.brandName)} />
        <p>{props.text}</p>
      </div>
    </a>
  );
};
