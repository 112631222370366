import React from 'react'
import './SocialMedia.scss'

interface SocialMediaProps {
    sites: {
        img: string;
        link: string;
        name: string;
    }[];
}

export const SocialMedia = (props: SocialMediaProps) => {
    return (
        <div>
            <div className='socialmedia'>
                {props.sites.map((e, i) => (<a key={i} className="socialmedia__site" href={e.link} target='_blank' rel="noreferrer">
                    <img src={e.img} alt="" className="socialmedia__site__img" />
                    <p className="socialmedia__site__text">{e.name}</p>
                </a>))}
            </div>
        </div>
    )
}
