import React from "react";
import "./SmartLink.scss";
import { SmartLinkService } from "./components/SmartLinkService";

export interface SmartLinkProps {
  release: any;
  maxHeight: number;
  opacity: number;
  inverted?: boolean;
}

export const SmartLink = (props: SmartLinkProps) => {
  const { release, maxHeight, inverted } = props;
  const imgParams = `?h=${maxHeight}&fit=thumb&fm=webp&fit=scale`;
  return (
    <div style={{ opacity: props.opacity, transition: "" + props.opacity * 0.2 + "s" }} className={`smart-link ${inverted && "inverted"}`}>
      <img className="smart-link__cover" src={release.cover.url + imgParams} style={{ maxHeight: props.maxHeight, objectFit: "cover" }} alt="Cover art for the release"></img>
      <div className="smart-link__content">
        <p className="smart-link__content__title">{release.title}</p>
        <div className="smart-link__content__specs">
          <span>{release.numberOfTracks} track{release.numberOfTracks > 1 ? "s" : ""} | {release.durationInMinutes} min | {release.dateOfRelease.substring(0, release.dateOfRelease.indexOf("-"))}</span>
        </div>

        <div className="smart-link__content__services">

          {release.linkSpotify && <SmartLinkService
            brandLogo="/img/spotify_35px.png"
            text="Listen"
            brandName="Spotify"
            url={release.linkSpotify}
          ></SmartLinkService>}
          {release.linkAppleMusic && <SmartLinkService
            brandLogo="/img/apple_30px.png"
            text="Listen"
            brandName="Apple Music"
            url={release.linkAppleMusic}
          ></SmartLinkService>}
          {release.linkSoundcloud && <SmartLinkService
            brandLogo="/img/soundcloud_25px.png"
            text="Listen"
            brandName="Soundcloud"
            url={release.linkSoundcloud}
          ></SmartLinkService>}
          {release.linkYt && <SmartLinkService
            brandLogo="/img/yt_25px.png"
            text="Watch"
            brandName="Youtube"
            url={release.linkYt}
          ></SmartLinkService>}
          {release.linkBandcamp && <SmartLinkService
            brandLogo="/img/bandcamp_30px.png"
            text="Free download"
            brandName="Bandcamp"
            url={release.linkBandcamp}
          ></SmartLinkService>}
          {release.linkBandcamp && <SmartLinkService
            brandLogo="/img/internet-archive_32px.png"
            text="Free download"
            brandName="Internet Archive"
            url={release.linkInternetArchive}
          ></SmartLinkService>}

        </div>
      </div>
    </div>
  );
};
