import React from 'react'
import './MusicPlatforms.scss'

interface MusicPlatformsProps {
    links?: {
        img: string;
        link: string;
    }[]
}

export const MusicPlatforms = (props: MusicPlatformsProps) => {
    return (
        <div>
            {/* <div style={{ marginBottom: 0, fontSize: "4rem" }} className='home__list__title'>SOCIAL MEDIA</div> */}
            <div style={{ margin: 0, marginTop: "25px", textShadow: "none", fontSize: "2rem" }} className='home__list__title'>WHERE TO LISTEN:</div>
            <div className='other-links'>
                {props.links?.map((e, i) => (<a key={i} className="other-links__site" href={e.link} target='_blank' rel="noreferrer">
                    <img src={e.img} alt="" className="links__site__img" />
                </a>))}
            </div>
        </div>
    )
}
