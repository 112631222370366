import React, { useState } from 'react'
import './NavBar.scss'
import { HiDotsHorizontal } from 'react-icons/hi'
import { IoClose } from 'react-icons/io5'
import { Link } from "react-scroll"


export const NavBar = () => {

    const [hidden, setHidden] = useState(true);
    return (
        <div className={'navbar' + (hidden ? ' hidden' : ' visible')}>
            <HiDotsHorizontal className="navbar__open-menu" onClick={() => { setHidden(false) }}></HiDotsHorizontal>
            <IoClose className="navbar__close-menu" onClick={() => { setHidden(true) }}></IoClose>
            <Link
                duration={500}
                delay={0}
                smooth={true}
                spy={true}

                to={"music-platforms-section"}
                activeClass="active"
                onClick={() => { setHidden(true) }}
            >
                <div className="navbar__option">PLATFORMS</div>
            </Link>
            <Link
                duration={500}
                delay={0}
                smooth={true}
                spy={true}

                to={"about-section"}
                activeClass="active"
                onClick={() => { setHidden(true) }}
            >
                <div className="navbar__option">ABOUT THIS</div>
            </Link>
            <Link
                duration={500}
                delay={0}
                smooth={true}
                spy={true}
                to={"discography-section"}
                activeClass="active"
                onClick={() => { setHidden(true) }}
            >
                <div className="navbar__option">DISCOGRAPHY</div>
            </Link>
            <Link
                duration={500}
                delay={0}
                smooth={true}
                spy={true}
                to={"social-media-section"}
                activeClass="active"
                onClick={() => { setHidden(true) }}
            >
                <div className="navbar__option">SOCIAL MEDIA</div>
            </Link>
            {/* <Link
                duration={500}
                delay={0}
                smooth={true}
                spy={true}
                to={"other-links-section"}
                activeClass="active"
                onClick={() => { setHidden(true) }}
            >
                <div className="navbar__option">OTHER LINKS</div>
            </Link> */}
            <Link
                duration={500}
                delay={0}
                smooth={true}
                spy={true}
                to={"contact-section"}
                activeClass="active"
                onClick={() => { setHidden(true) }}
            >
                <div className="navbar__option">CONTACT</div>
            </Link>
        </div>
    )
}
