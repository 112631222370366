import './Home.scss'
import { NavBar } from '../navBar/NavBar'
import { ContentBlock } from '../contentBlock/ContentBlock'
import { SocialMedia } from '../socialMedia/SocialMedia'
import { MusicPlatforms } from '../musicPlatforms/MusicPlatforms'
import { Releases } from '../releases/Releases'

export const Home = () => {
    return (
        <div className='home'>
            <NavBar></NavBar>
            <div className='home__list'>
                <div id="music-platforms-section" className='section'>
                    <MusicPlatforms links={[
                        {
                            img: "/img/spotify_35px.png",
                            link: "https://open.spotify.com/artist/3H2YIs1W0QMzQmFKIZGXsE",
                        },
                        {
                            img: "/img/apple_30px.png",
                            link: "https://music.apple.com/cl/artist/sound-stabs/id1506774714",
                        },
                        {
                            img: "/img/soundcloud_25px.png",
                            link: "https://soundcloud.com/soundstabsmusic",
                        },
                        {
                            img: "/img/yt_25px.png",
                            link: "https://www.youtube.com/user/soundstabs",
                        },
                        {
                            img: "/img/bandcamp_30px.png",
                            link: "https://soundstabs.bandcamp.com/",
                        },
                        {
                            img: "/img/internet-archive_32px.png",
                            link: "https://archive.org/details/@sound_stabs?tab=uploads&sort=-date",
                        },
                    ]}
                    ></MusicPlatforms>
                </div>
                <ContentBlock maxHeight='500px'>
                    <h1>About this:</h1>
                    <p>Sound Stabs is an electronic music project that started in 2012. </p>
                    <p>This site aims to be an official source of info, news and music.</p>
                    <p>All original songs are released for free under Creative Commons Attribution license, so feel free to use and download anything you like.</p>

                </ContentBlock>
                <div id="discography-section" className='section' style={{ display: "table" }}>
                    <div className='home__list__title'>DISCOGRAPHY</div>
                    <Releases defaultTypeOfRelease='Album'></Releases>
                </div>
                <div id="social-media-section" className='section'>
                    <div style={{ marginBottom: 0, fontSize: "4rem" }} className='home__list__title'>SOCIAL MEDIA</div>
                    <SocialMedia sites={[
                        {
                            img: "/img/tw_168px.png",
                            link: "https://twitter.com/soundstabs",
                            name: "@soundstabs"
                        },
                        {
                            img: "/img/ig_168px.png",
                            link: "https://www.instagram.com/sound.stabs/",
                            name: "@sound.stabs"
                        },
                        {
                            img: "/img/tiktok_168px.png",
                            link: "https://www.tiktok.com/@soundstabs",
                            name: "@soundstabs"
                        }]}
                    ></SocialMedia>
                </div>
                <div id="contact-section">
                    <div style={{ margin: 0, fontSize: "4rem" }} className='home__list__title'>CONTACT</div>
                    <p className="contact" style={{ textAlign: "center" }}>contact@soundstabs.com</p></div>
            </div>
        </div >
    )

}
