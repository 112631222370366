import './MailSubscription.scss'
import { IoIosArrowRoundForward, IoIosArrowRoundDown } from 'react-icons/io'
import { SubscriptionSuccess } from '../subscriptionSuccess/SubscriptionSuccess'

interface MailSuscriptionProps {
    darkMode?: boolean;
}

export const MailSuscription = (props: MailSuscriptionProps) => {

    return (
        <>
            <SubscriptionSuccess></SubscriptionSuccess>
            <script type="text/javascript" dangerouslySetInnerHTML={{
                __html: `
              if (typeof MauticSDKLoaded == 'undefined') {
        var MauticSDKLoaded = true;
          var head            = document.getElementsByTagName('head')[0];
          var script          = document.createElement('script');
          script.type         = 'text/javascript';
          script.src          = 'https://email.soundstabs.com/index.php/media/js/mautic-form.js?vbab4633e';
          script.onload       = function() {
              MauticSDK.onLoad();
        };
          head.appendChild(script);
          var MauticDomain = 'https://email.soundstabs.com/index.php';
          var MauticLang   = {
              'submittingMessage': "Please wait..."
        }
    }else if (typeof MauticSDK != 'undefined') {
              MauticSDK.onLoad();
    }`}}>
            </script>

            <div className="email-wrapper" >

                <div id="mauticform_wrapper_getupdates" className="mauticform_wrapper">
                    <form autoComplete="false" onSubmit={() => localStorage.setItem('emailSent', "true")} method="post" action="https://email.soundstabs.com/form/submit?formId=1" id="mauticform_getupdates" data-mautic-form="getupdates" encType="multipart/form-data">
                        <div className="mauticform-error" id="mauticform_getupdates_error"></div>
                        <div className="mauticform-message" id="mauticform_getupdates_message"></div>
                        <div className="mauticform-innerform">

                            <div className="mauticform-page-wrapper mauticform-page-1" data-mautic-form-page="1">

                                <p id="mauticform_getupdates_text">{"Do you want an email if there's new music?"}</p> <IoIosArrowRoundForward className='email-arrow-icon right'></IoIosArrowRoundForward><IoIosArrowRoundDown className='email-arrow-icon down'></IoIosArrowRoundDown>

                                <div id="mauticform_getupdates_email" data-validate="email" data-validation-type="email" className="mauticform-row mauticform-email mauticform-field-1 mauticform-required">
                                    {/* <label id="mauticform_label_getupdates_email" htmlFor="mauticform_input_getupdates_email" className="mauticform-label">Get notified about new releases</label> */}
                                    <input id="mauticform_input_getupdates_email" name="mauticform[email]" className="mauticform-input" type="email" placeholder='Your email here' required={true} />
                                    <span className="mauticform-errormsg" style={{ display: "none" }}>This is required.</span>
                                </div>
                                <div id="mauticform_getupdates_submit" className="mauticform-row mauticform-button-wrapper mauticform-field-2">
                                    <button type="submit" name="mauticform[submit]" id="mauticform_input_getupdates_submit" className="mauticform-button btn btn-default">Join</button>
                                </div>
                            </div>
                        </div>

                        <input type="hidden" name="mauticform[formId]" id="mauticform_getupdates_id" value="1" />
                        <input type="hidden" name="mauticform[return]" id="mauticform_getupdates_return" value="" />
                        <input type="hidden" name="mauticform[formName]" id="mauticform_getupdates_name" value="getupdates" />

                    </form>
                </div></div>

        </>
    )
}
