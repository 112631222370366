import React, { useEffect, useState } from 'react'
import { SmartLink } from '../smartLink/SmartLink';
import { TypeSelector } from '../typeSelector/TypeSelector';
import { useParams } from 'react-router-dom';
import { MailSuscription } from '../mailSubscription/MailSubscription';

interface ReleasesProps {
    defaultTypeOfRelease?: string;
}

export const Releases = (props: ReleasesProps) => {

    function createQuery(releaseType: string | undefined, releaseId: string | undefined): string {
        let whereClause = "";

        if (releaseType && releaseId) {
            whereClause = `(where: { type: "${releaseType}", id: "${releaseId}",`;
        } else if (releaseType && !releaseId) {
            whereClause = `(where: { type: "${releaseType}",`;
        } else if (!releaseType && releaseId) {
            whereClause = `(where: {id: "${releaseId}",`;
        } else {
            whereClause = `(where: {`
        }

        return `{
            releaseCollection${whereClause} hidden: false})
            {
                items{
                        title
                cover{
                    url
                }
                linkYt
                linkSoundcloud
                linkBandcamp
                linkSpotify
                linkInternetArchive
                linkAppleMusic
                numberOfTracks
                durationInMinutes
                dateOfRelease
                type
                }
            }
            }`};

    const [data, setData] = useState<any>(null);
    const [opacity, setOpacity] = useState<number>(1);
    const [selectedTypeOfRelease, setSelectedTypeOfRelease] = useState<string | undefined>(props.defaultTypeOfRelease);
    const { releaseId } = useParams();


    useEffect(() => {
        setOpacity(0);
        const query = createQuery(selectedTypeOfRelease, releaseId);
        window
            .fetch(`https://graphql.contentful.com/content/v1/spaces/vhhc2r6p4e9m/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer q1se1gmCR53XGBronMoRH23-7Rq81-5qObg8uplFn2g",
                },
                body: JSON.stringify({ query }),
            })
            .then((response) => response.json())
            .then(({ data, errors }) => {
                if (errors) {
                    console.error(errors);
                }
                setData(data);
                setOpacity(1);
            });
    }, [selectedTypeOfRelease, releaseId]);

    if (!data) {
        return "Loading...";
    }
    else {
        return <>
            {!releaseId && <MailSuscription></MailSuscription>}
            {selectedTypeOfRelease && <TypeSelector selectedType={{ setter: setSelectedTypeOfRelease, value: selectedTypeOfRelease }}></TypeSelector>}
            {data.releaseCollection.items
                .sort((a: any, b: any) => new Date(a.dateOfRelease) > new Date(b.dateOfRelease) ? -1 : 1)
                .map((element: any, i: number) => (
                    <SmartLink key={"smartlink-" + element.title} opacity={opacity} release={element} maxHeight={450} inverted={i % 2 === 1}></SmartLink>
                ))}
            <div style={{ height: "20px" }}></div>
            {releaseId && <MailSuscription darkMode={true}></MailSuscription>}
        </>
    }
}