import React from "react";
import "./ContentBlock.scss";

export interface ContentBlockProps {
  children?: React.ReactNode;
  imageSrc?: string;
  inverted?: boolean;
  maxHeight?: string;
}

export const ContentBlock = (props: ContentBlockProps) => {
  return (
    <div id="about-section" className="content-block" style={{ flexDirection: props.inverted ? "row-reverse" : "row" }}>
      {props.imageSrc && (<img className="content-block__cover" src={props.imageSrc} style={{ maxHeight: props.maxHeight }} alt="Iamge"></img>)}
      <div className="content-block__content" style={{ width: props.imageSrc ? "66%" : "" }}>
        <div className="content-block__content__text">
          {props.children}
        </div>
      </div>
    </div>
  );

};
