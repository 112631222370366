import React, { Dispatch } from 'react'
import './TypeSelector.scss'

interface TypeSelectorProps {
    selectedType: {
        value: string;
        setter: Dispatch<any>;
    }
}

export const TypeSelector = (props: TypeSelectorProps) => {
    return (
        <div className='type-selector'>
            <div onClick={() => props.selectedType.setter("Album")} className={`type-selector__option ${props.selectedType.value === "Album" ? "active" : ""}`}>Albums</div>
            <div onClick={() => props.selectedType.setter("Single")} className={`type-selector__option ${props.selectedType.value === "Single" ? "active" : ""}`}>Singles</div>
        </div>
    )
}
