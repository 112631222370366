import React from 'react'
import { Home } from "./components/home/Home";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { NotFound } from "./components/notFound/NotFound";
import { Releases } from "./components/releases/Releases";

export const App = () => {

    const ROUTES = [
        {
            path: "/",
            component: <Home />,
        },
        {
            path: "/home",
            component: <Home />,
        },
        {
            path: "/music",
            component: <Releases />,
        },
        {
            path: "/music/:releaseId",
            component: <Releases />,
        },
        {
            path: "/*",
            component: <NotFound />,
        }
    ];

    return (
        <BrowserRouter>
            <Routes>
                {ROUTES.map((route, i) => (
                    <Route path={route.path} key={i} element={route.component} />
                ))}
            </Routes>
        </BrowserRouter>
    )
}