import React, { useEffect, useState } from 'react'
import './SubscriptionSuccess.scss';




export const SubscriptionSuccess = () => {
    const [opacity, setOpacity] = useState<number>(localStorage.getItem('emailSent') === "true" ? 1 : 0);
    useEffect(() => {
        if (opacity === 1) {
            localStorage.setItem('emailSent', "false")
            setTimeLeft(4);
            setTimeout(() => setTimeLeft(3), 1000);
            setTimeout(() => setTimeLeft(2), 2000);
            setTimeout(() => setTimeLeft(1), 3000);
            setTimeout(() => setOpacity(0), 4000);
        }
    }, [opacity]);
    const [timeLeft, setTimeLeft] = useState(4);
    return (
        <div className='sub-success' style={{ opacity: opacity, pointerEvents: opacity === 1 ? "auto" : "none" }}>
            <h1>Email registered succesfully</h1>
            <p>We'll redirect you back in {timeLeft}...</p>
        </div>
    )
}
